@import "../../assets/theme/_var.scss";

.profile_modal_ref {
  max-width: 600px;
  position: relative;
  // bottom: 260px;
  .modal_headerStyle {
    .cross_btn {
      width: auto;
    }
    h2 {
      padding: 0;
      text-align: left;
    }
    p {
      margin: 12px 0;
    }
    .modal_headerStyle__rowA_colLeft {
      display: flex;
      flex-direction: column;
      justify-content: flex-start !important;
      align-items: baseline;
      p {
        font-size: 13px;
      }
    }
  }
  .modal_headerStyle__rowB {
    justify-content: center;
    .wallet_list_ref {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      input.form-control {
        width: 526px;
        font-family: $secondaryFont;
        padding-left: 25px;
        border-radius: 30px;
        border: 1px solid #496989;
        background-color: #000b29;
        height: 50px;
        &::placeholder {
          color: #496989;
          font-size: 16px;
        }
      }
      .sbmt_btn {
        margin: 23px 0 29px 0;
      }
      .input_ref {
        background: #000b29 0% 0% no-repeat padding-box;
        border: 1px solid #496989;
        border-radius: 30px;
        opacity: 1;
        width: 526px;
        height: 49px;
        color: $white;
        padding: 0 20px;
      }
    }
  }
}

.container_Ref {
  background-image: url(../../assets/images/facelift.jpg) !important ;
  height: 100%;
  background-size: cover;
  width: 100% !important;
  background-position: center;
}

.ReactModalPortal {
  .ReactModal__Content.ReactModal__Content--after-open {
    background: rgba(255, 255, 255, 0) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
  }
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background: rgba(255, 255, 255, 0) !important;
  }
}
