@import "../../assets/theme/_var.scss";

.customPagination {
  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    display: flex;
    gap: 22px;
    color: $whiteColor;
    @media (max-width: 1199px) {
      gap: 15px;
    }
    @media (max-width: 767px) {
      gap: 5px;
    }
    li {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 23px;
      font-weight: 400;
      font-family: $secondaryFont;
      min-width: 52px;
      height: 52px;
      border: 1px solid $secondaryColor;
      border-radius: 10px;
      transition: all linear 0.2s;
      @media (max-width: 1199px) {
        font-size: 18px;
        min-width: 40px;
        height: 40px;
      }
      @media (max-width: 767px) {
        font-size: 13px;
        min-width: 30px;
        height: 30px;
      }
      &:hover,
      &.active {
        color: $darkBlue;
        background-color: $secondaryColor;
      }
    }
  }
}
