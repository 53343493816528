@import "../../../assets/theme/_var.scss";

.container_Refpage {
  height: 100%;

  .refpage {
    background-image: url(../../../assets/images/facelift.jpg);
    background-size: cover;
    background-position: center;
    text-align: center;
    padding-top: 110px;
    min-height: 333px;
    @media (max-width: 767px) {
      min-height: 200px;
      padding-top: 50px;
    }
    h1 {
      font-size: 55px;
      font-family: $secondaryFont;
      font-weight: normal;
      color: $secondaryColor;
      margin: 0;
      @media (max-width: 767px) {
        font-size: 36px;
      }
    }
    p {
      margin-top: 21px;
      line-height: 16px;
      color: #fff;
      font-size: 13px;
      letter-spacing: 0.7px;
    }
  }
  .refpage {
    h3 {
      font-family: $secondaryFont;
    }
  }
  .row.referral {
    gap: 12px;
    justify-content: center;
    margin: 38px 0 0 38px;
    flex-wrap: wrap;
    @media (max-width: 1070px) {
      margin: 0px;
    }
    .head_content {
      width: 391px;
      border: 0.5px solid #e3d42d;
      border-radius: 11px;
      @media (max-width: 1070px) {
        width: 100%;
        margin: 20px;
      }
      &:nth-child(2) {
        h4 {
          background-color: $baseColor;
          color: $yellowColor;
          p {
            font-size: 21px;
          }
        }
      }
      &:nth-child(3) {
        h4 {
          background-color: $baseColor;
          color: $yellowColor;
          p {
            font-size: 21px;
          }
        }
      }
      h4 {
        font-size: 21px;
        font-family: $secondaryFont;
        height: 82px;
        margin: 0;
        font-weight: normal;
        padding: 32px 0 0 30px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: $secondaryColor;
      }
      p {
        font-size: 15px;
        background-color: $secondaryBg;
        height: 79px;
        padding: 25px 0px 35px 30px;
        color: $whiteColor;
        border-bottom-left-radius: 11px;
        border-bottom-right-radius: 11px;
        max-width: 80%;
      }
    }
  }
  .how_it_works_sec {
    // padding-left: 360px;
    // @media (max-width: 991px) {
    //   padding-left: 70px;
    // }
    h2 {
      font: normal normal normal 48px/58px Fredoka One;
      color: $yellowColor;
    }
    ul {
      counter-reset: my-sec-counter;
      .work_area {
        .work_img {
          position: relative;
          margin-top: 60px;
          margin-left: 95px;
          @media (max-width: 1070px) {
            margin: 30px 45px;
          }
          h4 {
            font: normal normal normal 25px/30px Fredoka One;
            color: $secondaryColor;
            margin: 0;
          }
          p {
            font: normal normal normal 13px/19px;
            letter-spacing: 0.46px;
            color: $whiteColor;
            margin-top: 14px;
          }
          &::after {
            counter-increment: my-sec-counter;
            content: counter(my-sec-counter);
            position: absolute;
            width: 78px;
            height: 78px;
            font-size: 35px;
            font-family: $secondaryFont;
            color: $white;
            border-radius: 50%;
            background-color: $baseColor;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $yellowColor;
            top: -7px;
            left: -95px;
          }
        }
      }
    }
  }
  .media_Wrap {
    background-color: #000b29;
    // padding-top: 85px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      padding: 0;
      margin-top: 35px;
    }
    .row {
      flex-wrap: wrap;
      @media (max-width: 1024px) {
        justify-content: center;
      }
    }
    .social_sec {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;
      background-color: #000b29;
      color: #fff;
      border: 1px solid #02a094;
      border-radius: 9px;
      width: 296px;
      margin-right: 15px;
      padding: 16px 22px;
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
      .social_head {
        display: block;
        align-items: center;
        justify-content: space-between;
        h2 {
          font-family: "Fredoka One", cursive;
          margin: 0 0 10px 0;
          font-weight: normal;
        }
        p {
          font-size: 10px;
        }
      }
    }
  }
}
.social_content {
  @media (max-width: 480px) {
    img {
      width: 30px;
    }
  }
}
