@import "../../assets/theme/_var.scss";

.analytic_card.card-body {
  background: $cardBg;
  border: 0;
  color: $white;
  font-weight: 600;
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 56px;
  @media (max-width: 1199px) {
    margin-bottom: 30px;
  }
  .analytic_detail {
    padding: 33px 37px 0;
    font-family: $secondaryFont;
    @media (max-width: 1199px) {
      padding: 22px 25px 0;
    }
    h4,
    .date {
      font-size: 17px;
      color: rgba(255, 255, 255, 0.56);
      font-weight: 400;
      margin: 0;
      @media (max-width: 1199px) {
        font-size: 15px;
      }
    }
    .activ {
      font-size: 31px;
      font-weight: 400;
      margin: 8px 0;
      @media (max-width: 1199px) {
        font-size: 26px;
        margin: 5px 0;
      }
    }
  }
  .graph {
    img {
      width: 100%;
    }
  }
}
