@import "../../assets/theme/_var.scss";

.backdrop {
  background: rgba(0, 11, 41, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

.profile_modal {
  position: absolute;
  top: 207px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 410px;
  // transform: translateY(-50%);
  z-index: 9;
  @media (max-width: 767px) {
    left: 10px;
    right: 10px;
  }
}
.modal_headerStyle {
  border-bottom: 1px solid $secondaryColor;
  margin-bottom: 25px;
}

.modal_headerStyle__rowA {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  .modal_headerStyle__rowA_colLeft {
    display: flex;
    align-items: center;
    h2 {
      font-family: $secondaryFont;
      font-size: 18px;
      color: $whiteColor;
      font-weight: 400;
      margin-right: 5px;
    }
  }
  .modal_headerStyle__rowA_colRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.modal_headerStyle__rowB {
  padding-left: 20px;
  padding-right: 20px;
  .modal_headerStyle__rowB_searchInput {
    input {
      height: 66px;
      border-radius: 20px;
      border: 1px solid $secondaryColor;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      background: transparent;
      font-family: $baseFont;
      font-size: 16px;
      font-weight: 500;
      color: $whiteColor;

      &:focus-visible,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.modal_headerStyle__rowC {
  padding-left: 20px;
  padding-right: 20px;
  .modal_headerStyle__rowC_colLeft {
    display: flex;
    align-items: center;
    h2 {
      font-family: $secondaryFont;
      font-size: 16px;
      color: $whiteColor;
      font-weight: 400;
      margin-right: 5px;
    }
  }
  .modal_headerStyle__rowC_colRight {
    display: flex;
    justify-content: flex-end;
    button {
      padding: 0;
      background: transparent;
      border: 0px;
      cursor: pointer;
    }
  }
}

.borderClassup {
  height: auto !important;
  padding: 0 25px 25px;
  .borderClass {
    border: 0px !important;
    padding: 0px;
  }
  h3 {
    margin-top: 0;
    padding-top: 0;
  }
  .lessMargin_bottom {
    border: 1px solid #000;
    background-color: #000;
    border-radius: 20px;
    padding: 28px 25px;
  }
  .tokenList {
    box-sizing: border-box;
    li {
      margin-bottom: 20px;
      .tokenName_textStyle {
        font-family: $secondaryFont;
        font-size: 16px;
        color: $whiteColor;
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }
}
.wallet-form input {
  border: none !important;
  height: auto !important;
}
.scan-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .sc-bdfBwQ {
    fill: #e3d32d;
    margin-left: 10px;
  }
  a {
    color: #e3d32d;
    display: flex;
    margin-right: 15px;
    font-weight: bold;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  .linkBlock {
    color: #e3d32d;
    display: flex;
    margin-right: 15px;
    font-weight: bold;
  }
}
.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy-icon {
  width: 15px;
  margin-left: 10px;
}
.close-col {
  justify-content: space-between;
}
