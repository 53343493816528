@import '../../assets/theme/_var.scss';

.backdrop{
    background: rgba(0,11,41, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    left:0px;
    top:0px;
    z-index: 6;
}

.selectCurrency_modal{
    position: absolute;
    top:50%;
    left:0;
    right: 0;
    margin:0 auto;
    max-width: 410px;
    transform: translateY(-50%);
}
.modal_headerStyle{
    border-bottom: 1px solid $secondaryColor;
    margin-bottom: 25px;
}

.modal_headerStyle__rowA{
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    .modal_headerStyle__rowA_colLeft{
        display: flex;
        align-items: center;
        h2{
            font-family: $secondaryFont;
            font-size: 18px;
            color: $whiteColor;
            font-weight: 400;
            margin-right: 5px;
        }
        
    }
    .modal_headerStyle__rowA_colRight{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.modal_headerStyle__rowB{
    padding-left: 20px;
    padding-right: 20px;
    .modal_headerStyle__rowB_searchInput{
       input{
           height: 66px;
           border-radius: 20px;
           border:1px solid $secondaryColor;
           width: 100%;
           padding-left: 20px;
           padding-right: 20px;
           box-sizing: border-box;
           background: transparent;
           font-family: $baseFont;
           font-size: 16px;
           font-weight: 500;
           color: $whiteColor;

           &:focus-visible, &:focus{
               outline: none;
               box-shadow: none;
           }

       } 
    }
}

.modal_headerStyle__rowC{
    padding-left: 20px;
    padding-right: 20px;
    .modal_headerStyle__rowC_colLeft{
        display: flex;
        align-items: center;
        h2{
            font-family: $secondaryFont;
            font-size: 16px;
            color: $whiteColor;
            font-weight: 400;
            margin-right: 5px;
        }
    }
    .modal_headerStyle__rowC_colRight{
        display: flex;
        justify-content: flex-end;
        button{
            padding: 0;
            background: transparent;
            border:0px;
            cursor: pointer;
            color:#fff;
            font-weight: bold;
        }
    }
}

.tokenList__column{
    height: 250px;
    overflow-y:auto ;
    overflow-x:hidden ;
   
    .tokenList{
        box-sizing: border-box;
        li{
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .dis{
                opacity: 0.5;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
            }
            .tokenName_textStyle{
                font-family: $secondaryFont;
                font-size: 16px;
                color: $whiteColor;
                font-weight: 400;
                margin-left: 10px;

            }
            .add_token{
                cursor: pointer;
                color: #E3D32D!important;
            }
        }
   
    }
}



