@import "../../../assets/theme/_var.scss";

.AnalyticTab {
  &.container_wrap {
    // padding: 50px 0;
    background: $secondaryBg;
    .row {
      @media (max-width: 991px) {
        display: block;
      }
    }
  }

  .bg_box {
    background-color: $cardBg;
    margin-bottom: 35px;
    border-radius: 10px;
    p {
      font-size: 14px;
    }
  }
  .container {
    max-width: 1065px;
    position: relative;
    padding: 0 15px;
  }
  .row:not(:last-child) {
    margin-bottom: 32px;
  }
  .analyticSearch {
    position: absolute;
    top: -2px;
    right: 12px;
    @media (max-width: 767px) {
      top: 0;
    }
    @media (max-width: 600px) {
      top: -42px;
      transform: translate(50%, 0px);
      right: 50%;
    }
    .formGroup {
      .customInput {
        background-image: url(../../../assets/images/searchIcon.svg);
        background-repeat: no-repeat;
        background-position: center right 25px;
        background-size: 25px;
        @media (max-width: 767px) {
          background-size: 15px;
        }
      }
    }
  }
  // .nav-pills {
  //   background-color: $swapStepbg;
  //   width: max-content;
  //   border-radius: 35px;
  //   height: 50px;
  //   margin-bottom: 35px;
  //   .nav-item {
  //     &.nav-link {
  //       display: inline-flex;
  //       justify-content: center;
  //       align-items: center;
  //       border: 0;
  //       border-radius: 35px;
  //       min-width: 130px;
  //       font-size: 16px;
  //       color: $secondaryColor;
  //       height: 50px;
  //       font-weight: 400;
  //       font-family: $secondaryFont;
  //       text-decoration: none;
  //       @media (max-width: 767.98px) {
  //         font-size: 13px;
  //       }
  //       &.active {
  //         background: $secondaryColor;
  //         // font-size: 15px;
  //         // padding-left: 20px;
  //         // padding-right: 20px;
  //         color: $baseColor;
  //         @media (max-width: 767.98px) {
  //           font-size: 13px;
  //         }
  //       }
  //     }
  //   }
  // }
  .table_area {
    background-color: transparent;
    padding-top: 14px;
    border-radius: 10px;
    margin-bottom: 25px;
    .table-responsive {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .dataTable {
      width: 100%;
      border-collapse: collapse;
      @media (max-width: 767.98px) {
        min-width: 700px;
      }
      &.big_table {
        @media (max-width: 991.98px) {
          min-width: 900px;
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              padding-left: 20px;
            }
            span {
              vertical-align: middle;
            }
          }
          &:nth-child(odd) {
            background-color: #d8e4ea;
          }
          &:nth-child(even) {
            background-color: #fafafa;
          }
          &:first-child {
            td:first-child {
              border-top-left-radius: 14px;
            }
            td:last-child {
              border-top-right-radius: 14px;
            }
          }
          &:last-child {
            td:first-child {
              border-bottom-left-radius: 14px;
            }
            td:last-child {
              border-bottom-right-radius: 14px;
            }
          }
          .forward_link {
            margin-left: 10px;
          }
          .actionLink a {
            color: #4072ff;
          }
        }
      }
      tr {
        border: 0;
        font-size: 15px;
        color: $white;
        vertical-align: middle;
        @media (max-width: 767.98px) {
          font-size: 13px;
        }

        th {
          border: 0;
          color: $whiteColor;
          font-weight: 700;
          font-size: 11px;
          padding: 18px 10px;
          text-align: left;

          &.colorYellow {
            color: $secondaryColor;
          }
          &.sr_no {
            width: 15px;
            text-align: right;
          }
        }
        td {
          border: 0;
          padding: 16.5px 10px;
          font-size: 14px;
          color: $darkBlue;
          font-weight: 500;
          @media (max-width: 767.98px) {
            padding: 10px 7px;
            font-size: 12px;
          }
          &.actionLink {
            color: #4072ff;
          }
          &.icon_id {
            text-transform: uppercase;
            display: flex;
            align-items: center;
            span {
              display: inline-block;
            }
            img {
              width: 28px;
              margin-right: 10px;
              @media (max-width: 767.98px) {
                width: 20px;
              }
            }
          }
          &.pool {
            img {
              margin: 0;
              position: relative;
              z-index: 9;
            }
            .backface {
              z-index: 1;
              margin-left: -10px;
            }
            span {
              min-width: 65px;
              display: inline-block;
              @media (max-width: 767.98px) {
                min-width: 40px;
              }
            }
          }
          .up {
            color: $textGreen;
            display: flex;
            align-items: center;
            img {
              width: 8px;
              margin-right: 5px;
            }
          }
          .down {
            color: $textRed;
            display: flex;
            align-items: center;
            img {
              width: 8px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .trans_tab_view {
    .tab-header {
      background-color: transparent;
      width: auto;
      height: fit-content;
      margin: 25px 0;
      @media (max-width: 1199px) {
        margin: 15px 0;
      }

      li {
        height: fit-content;
        border: 0;
        border-radius: 35px;
        min-width: auto;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.44);
        font-weight: 400;
        text-decoration: none;
        position: relative;
        font-family: $secondaryFont;
        padding-left: 30px;
        margin-right: 20px;
        @media (max-width: 767.98px) {
          font-size: 13px;
          margin-bottom: 15px;
        }
        &.active {
          background: transparent;
          color: $white;
          &:after {
            background: $secondaryColor;
            width: 22px;
            height: 22px;
            // @media (max-width: 767.98px) {
            //   width: 15px;
            //   height: 15px;
            // }
          }
          &::before {
            background-color: $darkBlue;
            position: absolute;
            width: 13px;
            height: 13px;
            border-radius: 50px;
            content: "";
            left: 4px;
            // top: 4px;
            z-index: 1;
            // @media (max-width: 992px) {
            //   top: 3px;
            // }
            // @media (max-width: 767px) {
            //   top: 2px;
            // }
          }
        }
        &:after {
          position: absolute;
          width: 22px;
          height: 22px;
          content: "";
          background: $lightBg;
          border-radius: 50px;
          left: 0;
        }
      }
    }
  }
  .trans_in {
    margin-top: 0;
  }
}

.analyticChart_top {
  .chartRow {
    gap: 14px;
  }
  .priceRow {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 37px;
    @media (max-width: 1199px) {
      margin-bottom: 20px;
    }
    @media (max-width: 1060px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .theme_title {
        margin-bottom: 15px;
      }
    }
  }
  .anchorData {
    display: flex;
    gap: 35px;
    color: $secondaryColor;
    font-size: 11px;
    font-weight: 700;
    white-space: nowrap;
    flex-wrap: wrap;
    @media (max-width: 1199px) {
      font-size: 10px;
      gap: 20px;
    }
    .textWhite {
      color: $whiteColor;
    }
  }
}
.theme_title {
  font-size: 23px;
  color: $whiteColor;
  font-weight: 400;
  font-family: $secondaryFont;
  margin: 0;
  @media (max-width: 1199px) {
    font-size: 20px;
  }
}
.yourWatchlist {
  padding: 18px 0 36px;
  .theme_title {
    margin-bottom: 28px;
  }
  .customSearch_style {
    width: 100%;
    .formGroup {
      width: 100%;
    }
  }
}
.paddLeft {
  padding-left: 20px !important;
}

.token_row {
  gap: 27px;
}
.col_sec {
  width: 273px;
  .left_design {
    border: 1px solid #496989;
    border-radius: 20px;
    padding: 0 47px 0;
    min-height: 480px;
    max-width: 273px;
    .info_design {
      display: flex;
      flex-direction: column;
      &:nth-child(1) {
        margin-top: 40px;
      }
      &:nth-child(1) {
        h5 {
          color: #b90107;
        }
      }
      &:nth-child(2) {
        h5 {
          color: #00b94e;
        }
      }
      h3 {
        color: #496989;
        font-size: 16px;
        margin: 0;
        font-weight: normal;
      }
      h2 {
        font-size: 26px;
        font-family: $secondaryFont;
        color: $white;
        margin: 7px 0 0 0;
        font-weight: normal;
      }
      h5 {
        font-size: 19px;
        font-family: $secondaryFont;
        margin: 3px 0 0 0;
        font-weight: normal;
        &:nth-child(2){
          color:#00b94e;
        }
      }
    }
    .hl {
      max-width: 184px;
      height: 2px;
      background-color: #496989;
      margin: 10px 0 28px 0;
    }
  }
}

.right_design {
  .token_tabs {
    .tab-header {
      height: 60px;
      border-radius: 16px 16px 0 0;
      li {
        width: 149px;
        height: 60px;
        border-radius: 16px 16px 0 0;
      }
    }
  }
}

.right_token_graph {
  color: $whiteColor;
  max-width: 733px;
  height: 304px;
  border: 1px solid #496989;
  border-radius: 0px 0px 20px 20px;
  margin-top: -35px;
  padding: 20px 0 0 20px;

  h2 {
    margin: 0;
  }
  h4 {
    margin: 0;
    font-weight: normal;
  }
}
.col_sec1 {
  width: 733px;
}
.breadcrumb_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
  .breadcrumb {
    display: flex;
    font-size: 16px;
    font-family: $secondaryFont;
    padding: 0 20px;
    nav {
      ol {
        li {
          font-size: 16px;
          a {
            color: #496989;
          }
        }
      }
    }
  }
  p {
    color: #4072ff;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    // span {
    //   .darrow {
    //     max-width: 20%;
    //   }
    // }
  }
  img {
    max-width: 20px;
    margin: 0 14px;
    color: #496989;
  }
}
.bnb_row {
  justify-content: space-between;
  align-items: center;

  .bnb_token {
    display: flex;
    align-items: center;
    gap: 11px;
    img {
      width: 70px;
      height: 70px;
      // margin-right: 20px;
    }
    h2 {
      color: $whiteColor;
      font-size: 35px;
      font-family: $secondaryFont;
      font-weight: normal;

      span {
        font-size: 22px;
        color: #496989;
      }
    }
  }
  .bnb_btns {
    display: flex;
    gap: 11px;
    button {
      &:nth-child(1) {
        width: 197px;
        height: 46px;
        background: #111326;
        color: #e3d32d;
      }
      &:nth-child(2) {
        width: 158px;
        height: 46px;
      }
    }
  }
}
li {
  font-size: 16px;
  a {
    color: #496989;
  }
}
.breadcrumb-item.active {
  color: #fff;
}


.value_token{
  span{
    font-family: "Fredoka One", cursive;
    &:nth-child(1){
      font-size: 26px;
      color: #fff;
    }
    &:nth-child(2){
      font-size: 19px;
      color: #00B94E;
      margin-left: 20px;
    }
  }
}