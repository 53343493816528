$baseFont:'Rubik', sans-serif;
$secondaryFont:'Fredoka One', cursive;
$baseColor:#111326;
$basebg: #000B29;
$secondaryColor:#E3D32D;
$yellowColor: #e3d42d;
$whiteColor:#fff;
$blackColor:#000;
$blueLign:#1C3D5A;
$swapStepbg:#496989;
$buttonDisabled:#496989;
$lightBg: #D8E4EA;
$noFeedColor:#00FFBA;
$darkBlue: #000B29;
$cardBg: transparent linear-gradient(180deg, #000A28 0%, #072141 34%, #20749B 100%) 0% 0%;
$secondaryBg: #121c38;
$white: white;
$textBlue: blue;
$textGreen: #00B94E;
$textRed: #B90007;
