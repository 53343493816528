@import "../../assets/theme/_var.scss";

.customSearch_style {
  display: block;
  form {
    width: 100%;
  }
  .formGroup {
    width: 284px;
    height: 50px;
    @media (max-width: 767px) {
      height: 35px;
      width: 220px;
    }
    .customInput {
      border-radius: 30px;
      border: 1px solid $swapStepbg;
      background-color: $darkBlue;
      width: 100%;
      height: 100%;
      font-size: 16px;
      font-weight: 400;
      font-family: $secondaryFont;
      color: $swapStepbg;
      padding-left: 32px;
      @media (max-width: 767px) {
        font-size: 13px;
        padding-left: 20px;
      }
      &::placeholder {
        color: $swapStepbg;
      }
      &:focus,
      &:focus-visible {
        border-color: $swapStepbg;
        outline: 0;
      }
    }
  }
}
