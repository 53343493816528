@import "../../assets/theme/_var.scss";

.tabs {
  width: 100%;
  .tab-header {
    background-color: $swapStepbg;
    margin-bottom: 35px;
    border-radius: 35px;
    height: 50px;
    max-width: fit-content;
    @media (max-width: 767px) {
      margin-bottom: 20px;
      height: 35px;
    }
    @media (max-width: 600px) {
      margin: 0 auto 20px;
    }
    li {
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 0;
      border-radius: 35px;
      min-width: 130px;
      font-size: 16px;
      color: $secondaryColor;
      height: 50px;
      font-weight: 400;
      font-family: $secondaryFont;
      text-decoration: none;
      @media (max-width: 767px) {
        font-size: 13px;
        height: 35px;
        min-width: 95px;
      }

      &.active {
        background: $secondaryColor;
        color: $baseColor;
      }
    }
  }

  .tab-content {
    height: fit-content;
    width: 100%;
    position: relative;
    .tab-child {
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
    }
  }
}
