@import "../../../assets/theme/_var.scss";

.container_Oceans {
  background: #121c38 url(../../../assets/images/facelift.jpg) center top
    no-repeat;
  background-attachment: fixed;
  background-size: cover;
  h2 {
    font-size: 37px;
    line-height: 49px;
    color: #fff;
    margin: 0;
    text-align: center;
    font-weight: 400;
    padding-top: 50px;
    font-family: $secondaryFont;
    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 35px;
    }
  }
  h5 {
    font-size: 13px;
    line-height: 17px;
    color: #fff;
    margin: 0;
    font-weight: 700;
    font-family: $baseFont;
    text-align: center;
  }
  .oceanInfoOut {
    display: flex;
    justify-content: space-around;
    padding-top: 35px;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 0 auto;
  }
  .oceanInfo {
    margin-bottom: 25px;
    .oceanInfoTop {
      border: 1px solid yellow;
      border-radius: 29px 29px 0 0;
      position: relative;
      overflow: hidden;
      img {
        display: flex;
        width: 100%;
      }
      a {
        position: absolute;
        right: 15px;
        top: 15px;
      }
    }
    .oceanInfoText {
      border: 1px solid #e3d42e;
      border-top: 0px !important;
      border-radius: 0 0 29px 29px;
      padding: 25px 40px;
      background: $baseColor;
      h3 {
        font-size: 25px;
        line-height: 33px;
        font-weight: 400;
        margin: 0;
        font-family: $secondaryFont;
        color: #fff;
        margin-bottom: 10px;
        span {
          color: #6cfefd;
        }
      }
      p {
        font-size: 13px;
        line-height: 22px;
        font-weight: 700;
        color: #fff;
        font-family: $baseFont;
        min-height: 120px;
      }
      a {
        font-size: 21px;
        color: #e3d42e;
        font-weight: 700;
        font-family: $baseFont;
      }
    }
  }
}
