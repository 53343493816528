@import "../../../assets/theme/_var.scss";

.container_wrap {
  margin-left: 236px;
  max-width: calc(100% - 236px);
  // min-height: 1000px;
  min-height: 100vh;
  // background: url(../../../assets/images/bg_home_new.jpg) top center no-repeat;
  background-color: #121c38;
  background-size: cover;
  transition: all 0.55;
  position: relative;

  // .container_inside {
  //   padding-top: 70px;
  //   padding-bottom: 70px;
  //   @media (max-width: 991px) {
  //     padding-top: 20px;
  //   }
  // }

  @media (max-width: 960px) {
    margin-left: 0px;
    max-width: 100%;
  }
}

body.expande_container .container_wrap {
  margin-left: 73px;
  max-width: calc(100% - 73px);

  @media (max-width: 960px) {
    margin-left: 0;
    max-width: 100%;
  }
}

// .container.container_inside.subheader {
//   background-image: url("../../../assets/images/eth-ocean.jpg");
.container-fluid.anchor {
  background-image: url("../../../assets/images/facelift.jpg");
  background-size: cover;
  .subheader_row {
    width: 100%;
    max-width: 1310px;
    align-items: center;
    padding: 0 95px;
    margin: auto;
    @media (max-width: 1366px) {
      gap: 98px;
    }
    @media (max-width: 1199px) {
      gap: 48px;
    }
    @media (max-width: 1024px) {
      gap: 0;
      flex-direction: column-reverse;
    }
    @media (max-width: 767px) {
      padding: 0 30px;
    }

    .subHeader_style {
      margin: 62px 0;
      @media (max-width: 1024px) {
        // margin: 30px 0;
        padding: 0 10px;
      }
      p {
        font-family: $baseFont;
        font-size: 13px;
        font-weight: 700;
        color: $whiteColor;
        margin-bottom: 20px;
      }
      .anchor_logo {
        max-width: 312px;
      }
      img {
        width: 100%;
        border-radius: 11px;
      }
      ul.head {
        li {
          font-size: 13px;
          color: #fff;
          line-height: 25px;
          position: relative;
          padding-left: 17px;
          &::before {
            content: "";
            position: absolute;
            height: 7px;
            width: 7px;
            border-radius: 50%;
            background-color: #e3d42d;
            top: 9px;
            left: 0px;
          }
        }
      }

      button.btn.buttonStyle.undefined {
        margin-top: 35px;
        width: 250px;
        height: 60px;
        border-radius: 35px;
        font-size: 18px;
      }
      .slick-slider {
        max-width: 572px;
        height: 337px;
        border: 0.5px solid #e3d42d;
        border-radius: 11px;
        opacity: 1;
        @media (max-width: 1366px) {
          max-width: 410px;
          height: 242px;
        }
        @media (max-width: 1199px) {
          max-width: 350px;
          height: 207px;
        }
        @media (max-width: 1024px) {
          max-width: 600px;
          height: 366px;
          order: 1;
        }
        @media (max-width: 767px) {
          max-width: 100%;
          height: auto;
        }
        &.slidr_style {
          .slick-dots {
            bottom: -35px;
            button {
              width: 15px;
              height: 15px;
              padding: 0;
              background: #fff;
              border-radius: 50%;
              &::before {
                width: 15px;
                height: 15px;
                font-size: 11px;
                line-height: 18px;
                color: #e3d32d;
              }
            }
          }
        }
      }
      .left_anchor_sec {
        @media (max-width: 1024px) {
          max-width: 572px;
          height: 336px;
          order: 2;
          text-align: center;
        }
      }
    }
  }
}

.subheader_row_new {
  gap: 5px;
  flex-wrap: nowrap;
  margin-top: 40px;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 15px;
  }
  .subHeader_new_style {
    border: 0.5px solid #e3d42d;
    border-radius: 11px;
    opacity: 1;
    padding: 19px 30px;
    max-width: 563px;
    width: 100%;
    background-color: #000b29;
    @media (max-width: 1024px) {
      max-width: 100%;
    }
    .anchor_price_sec {
      display: flex;
      justify-content: space-between;
      color: $whiteColor;
      h2 {
        margin: 0;
        padding: 0;
        font-size: 23px;
        @media (max-width: 767px) {
          font-size: 15px;
        }
      }
    }
    .link_sec {
      margin-top: 7px;
      display: flex;
      justify-content: end;
      font-size: 12px;
      span {
        display: flex;
        a {
          color: #e3d42d;
        }
      }
      .vl {
        width: 1px;
        height: 15px;
        background-color: #e3d42d;
        margin: 0 5px;
      }
    }
  }
}

.stats_row_sec {
  gap: 8px;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
    gap: 15px;
  }
  .substats_sec {
    max-width: 374px;
    width: 100%;
    position: relative;
    @media (max-width: 1024px) {
      max-width: 100%;
    }
    .cardHome_style {
      min-height: 100%;
      height: 307px;
      .cardHome_toptitle {
        font-size: 12px;
        padding: 24px 0 0 29px;
        line-height: normal;
      }
    }
    h2.cardHome_titleStyle {
      padding: 0 30px;
      margin: 6px 0;
      @media (max-width: 1366px) {
        padding: 0 20px;
        font-size: 24px;
      }
    }
    .col_lottery_winning_style {
      h4 {
        padding: 0 30px;
        margin: 8px 0;
        @media (max-width: 1366px) {
          font-size: 14px;
          padding: 0 20px;
        }
      }
      p {
        padding: 0 30px;
        line-height: 25px;
        @media (max-width: 1366px) {
          font-size: 13px;
          line-height: 1.2;
          padding: 0 20px;
        }
        a {
          color: $yellowColor;
        }
      }
    }
  }
  .cardHome_style {
    .cardHome_titleStyle {
      // background: $baseColor url(../../../assets/images/anchor-yellow@2x.png) 95%
      //   20% no-repeat !important;
      // min-height: 400px;
      // background-position: top right;
      color: #e3d42d;

      @media (max-width: 960px) {
        margin-bottom: 25px;
      }
    }
    &.no_img {
      img {
        display: none;
      }
    }
  }
}
.announcements_cardStyle {
  height: 100%;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
  @media (max-width: 1024px) {
    font-size: 10px;
  }
  @media (max-width: 767px) {
    height: auto;
    margin-bottom: 30px;
  }
}

.col_lottery_winning_style {
  // background: $baseColor
  //   url(../../../assets/images/anchorswap-lottery-background@2x.png) no-repeat !important;
  min-height: 400px;
}

.anchor_swapbtn_metamask_btn {
  align-items: center;

  .addMetamask_btn {
    position: relative;
    margin-left: 10px;
    transform: scale(1);
    transition: all 0.25s;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.anchor_harvest_list {
  font-family: $baseFont;
  li {
    // margin-bottom: 5px;
    padding: 13px 30px 0;
    display: flex;
    justify-content: space-between;
    height: 56px;
    &:nth-child(odd) {
      background-color: #121c38;
    }
    &:nth-child(even) {
      background-color: #000b29;
    }
  }
  .anchor_harvest_list__title {
    font-family: inherit;
    color: $whiteColor;
    font-style: 15px;
    // padding: 0 30px;
    // font-weight: 700;
  }
  .anchor_harvest_list__lockedTextStyle {
    font-family: inherit;
    color: #fff;
    font-style: 5px;
    // font-weight: 700;
  }
  .anchor_harvest_list__valueTextStyle {
    font-family: inherit;
    color: $secondaryColor;
    font-style: 13px;
    font-weight: 300;
  }
}

.token_row {
  justify-content: space-between;
  margin-top: 46px;
  margin-bottom: 70px;
  flex-wrap: wrap;
  gap: 15px;
  @media (max-width: 1366px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .token_info {
    width: 193px;
    display: flex;
    align-items: center;
    color: $whiteColor;
    &:last-child {
      justify-content: end;
    }
    img {
      width: 53px;
    }
    div {
      margin-left: 12px;
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h6 {
          margin: 0;
          font-size: 13px;
        }
        p {
          margin-left: 23px;
          font-size: 12px;
          &.up {
            color: $textGreen;
          }
          &.down {
            color: $textRed;
          }
        }
      }
      h3 {
        margin: 5px 0 0 0;
        padding: 0;
        font-size: 18px;
      }
    }
  }
  .btn {
    border-radius: 35px;
    font-size: 17px;
    width: 100%;
    max-width: 193px;
    color: #212e52;
    height: 51px;
    &:hover {
      color: #e3d32d;
    }
  }
}

.anchor_wrap {
  // background-color: #2C3C65;
  .announce_row {
    color: $whiteColor;
    flex-wrap: wrap;
    // padding: 0 95px;
    gap: 8px;
    .cardHome_style {
      padding: 125px 30px 30px;
      max-width: 374px;
      min-height: 444px;
      position: relative;
      margin: 0 8px 0 0;
      @media (max-width: 1450px) {
        // max-width: 350px;
      }
      @media (max-width: 991px) {
        max-width: 100%;
        min-height: none;
      }
      h2.cardHome_titleStyle {
        color: $yellowColor;
        margin: 0;
        margin-bottom: 6px;
      }
      h3 {
        font-size: 17px;
        margin-bottom: 24px;
        font-family: "Fredoka One", cursive;
      }
      p {
        letter-spacing: 0.46px;
        line-height: 19px;
        margin-bottom: 30px;
        font-size: 13px;
      }
      .btn.full {
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 11px;
        border-bottom-right-radius: 11px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        min-height: 71px;
        font-size: 20px;
        color: #212e52;
        &:hover {
          color: #e3d42d;
        }
      }
      &.no_img {
        img {
          display: none;
        }
      }
      img {
        position: absolute;
        top: 25px;
        width: 170px;
      }
    }
  }
  .title_sec {
    color: #fff;
    padding: 45px 0 21px 0;
    p {
      margin-bottom: 9px;
    }
    span {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      p {
        font-size: 13px;
      }
      .btn {
        border-radius: 35px;
        font-size: 17px;
        width: 100%;
        max-width: 193px;
        height: 51px;
        color: #212e52;
        &:hover {
          color: #e3d42d;
        }
      }
    }
    h2 {
      font-size: 48px;
      color: $yellowColor;
      margin: 0;
      font-weight: 300;
      font-family: $secondaryFont;
      @media (max-width: 1024px) {
        font-size: 32px;
      }
      @media (max-width: 767px) {
        font-size: 26px;
      }
    }
  }
}

.partner_Wrap {
  .title_sec {
    color: #fff;
    margin-top: 82px;
    h2 {
      font-size: 48px;
      margin: 0;
      font-family: $secondaryFont;
      color: $yellowColor;
      padding-top: 9px;
      @media (max-width: 1024px) {
        font-size: 32px;
      }
      @media (max-width: 767px) {
        font-size: 26px;
      }
    }
    p {
      font-size: 13px;
    }
  }
  .anchor_partners {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (max-width: 1150px) {
      justify-content: center;
    }
    @media (max-width: 767px) {
      .partner_img {
        display: none;
      }
    }
    .anc_partner_img {
      display: none;
      img {
        max-width: 100%;
      }
      @media (max-width: 767px) {
        display: block;
        margin-top: 20px;
      }
    }

    .partner_img {
      margin-top: 35px;
      img {
        width: 245px;
        margin-right: 32px;
        @media (max-width: 767px) {
          width: 135px;
          margin-right: 20px;
          display: inline;
        }
      }
    }
  }
}

.farm_row {
  gap: 8px;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    gap: 15px;
  }
  .farm_data {
    display: block;
  }
  .farm_info {
    border: 0.5px solid #e3d42d;
    border-radius: 11px;
    padding: 20px 30px;
    display: block;
    @media (max-width: 1366px) {
    }
    div {
      display: flex;
      align-items: center;
      span.eth_data {
        display: flex;
      }
      span.arr {
        display: flex;
        align-items: center;
      }
      span.sec {
        display: block;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 17px;
        margin-left: 15px;

        h2 {
          font-size: 17px;
          margin: 0;
          font-family: "Fredoka One", cursive;
        }
        p {
          font-size: 13px;
          margin-top: 6px;
        }
      }
      span {
        img.farm_img_one {
          z-index: 1;
          width: 53px;
        }
        img.farm_img_two {
          width: 53px;
          z-index: 2;
          margin-left: -10px;
        }
      }
    }
    .farm_detail {
      display: flex;
      color: #fff;
      justify-content: space-between;
      margin-top: 17px;
      align-items: baseline;
      font-family: "Fredoka One", cursive;
      span {
        color: #e3d42d;
      }
      h2 {
        font-size: 29px;
        margin: 0;
        @media (max-width: 1366px) {
          font-size: 14px;
        }
      }
      p {
        font-size: 13px;
      }
    }
  }
}

.media_Wrap {
  background-color: #000b29;
  display: flex;
  justify-content: center;
  // padding: 0 0 20px 155px;
  @media (max-width: 767px) {
    padding: 15px;
    margin-top: 35px;
  }
  .row {
    flex-wrap: wrap;
    @media (max-width: 1024px) {
      // max-width: 100%;
      justify-content: center;
      gap:10px;
    }
    @media (max-width: 767px) {
      width: 100%;
      justify-content: space-around;
      gap:10px;
    }
  }
  .social_sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    background-color: #000b29;
    color: #fff;
    border: 1px solid #02a094;
    border-radius: 9px;
    // width: 370px;
    margin-right: 15px;
    padding: 16px 35px;
    @media (max-width: 767px) {
      padding: 16px 30px;
      margin: unset;
      border: none;
    }
    @media (max-width: 480px) {
      padding: 10px;
    }
    .social_head {
      display: block;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 767px) {
        display: none;
      }
      h2 {
        font-family: "Fredoka One", cursive;
        margin: 0 0 10px 0;
      }
      p {
        font-size: 10px;
      }
    }
  }
}
.lottery_winning_list {
  font-family: $baseFont;
  // overflow-y: hidden;
  // height: 200px;
  li {
    // margin-bottom: 5px;
    width: 100%;
    &:nth-child(odd) {
      background-color: #121c38;
    }
    &:nth-child(even) {
      background-color: #000b29;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .lottery_winnig__title {
    font-family: inherit;
    color: $secondaryColor;
    font-size: 13px;
    font-weight: 700;
  }
  .lottery_winnig__value {
    font-family: inherit;
    color: $whiteColor;
    font-size: 27px;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
  .lottery_winnig__valuesmall {
    font-family: inherit;
    color: $secondaryColor;
    font-size: 13px;
    font-weight: 700;
  }
}

.unloack_btn_style {
  // margin-top: 18px;
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 71px;
    font-size: 20px;
    left: 0;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    color: #212e52;
    font-size: 20px;
    &:hover {
      color: #e3d42d;
    }
    img.down_arr {
      width: 12px;
      display: block !important;
      margin: 0 8px;
      // filter: invert(1);
    }
  }
}
button.btn.buttonStyle.full.final {
  background-color: #000b29;
  color: #e3d42d;
  border: none;
  border-radius: 0 0 10px 10px;
}

.list_ValueStyle {
  li {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    span.label {
      font-family: $baseFont;
      font-size: 13px;
      font-weight: 700;
      color: $whiteColor;
    }
    strong.value {
      font-family: $baseFont;
      font-size: 13px;
      font-weight: 700;
      color: $whiteColor;
    }
  }
}

// .totalValueLocked_style {
//   // font-size: 25px;
//   p {
//     color: $whiteColor;
//     font-size: 25px;
//     font-family: $secondaryFont;
//     margin-top: -10px;
//     margin-bottom: 5px;
//     @media (max-width: 1199px) {
//       font-size: 18px;
//     }
//   }

//   strong {
//     color: $secondaryColor;
//     font-size: 13px;
//     font-family: $baseFont;
//     font-weight: 700;
//   }
// }
.twitter-col {
  iframe {
    .timeline-Widget {
      max-width: 1200px;
      background-color: #1c3d5a !important;
      border-radius: 5px;
      color: #fff;
    }
  }
  .timeline-Viewport {
    scrollbar-width: none;
  }
}
.timeline-Viewport::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: thin !important;
}

ul.lottery_winning_list.modification {
  overflow-y: scroll;
  height: 200px;
  .full.final {
    span {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
