@import "../../../assets/theme/_var.scss";

.container_Ref {
  background-image: url(../../../assets/images/facelift.jpg);
  height: 100%;
  background-size: cover;
  background-position: center;
  .container.ref {
    max-width: 600px;
    text-align: center;
    padding: 224px 0 0 0;
    // img {
    //   width: 353px;
    // }
    .profile_modal_ref {
      max-width: 600px;
      position: relative;
      bottom: 260px;
      .modal_headerStyle {
        .cross_btn {
          width: auto;
        }
        h2 {
          padding: 0;
          text-align: left;
        }
        p {
          margin: 12px 0;
        }
        .modal_headerStyle__rowA_colLeft {
          display: flex;
          flex-direction: column;
        }
      }
      .modal_headerStyle__rowB {
        .wallet_list_ref {
          input.form-control {
            width: 526px;
            font-family: $secondaryFont;
            padding-left: 25px;
            border-radius: 30px;
            border: 1px solid #496989;
            background-color: #000b29;
            height: 50px;
            &::placeholder {
              color: #496989;
              font-size: 16px;
            }
          }
          .sbmt_btn {
            margin: 23px 0 29px 0;
          }
        }
      }
    }
    h2 {
      font-family: $secondaryFont;
      font-size: 23px;
      line-height: 35px;
      margin: 0;
      color: #fff;
      padding-top: 25px;
      span {
        color: $secondaryColor;
        text-decoration: underline;
      }
    }
    p {
      font-size: 13px;
      color: $white;
      margin-top: 21px;
      letter-spacing: 0.8px;
    }

    .lockbox {
      // padding: 36px 24px;
      // background-color: rgb(18, 24, 39);
      // border-radius: 32px;
      // box-shadow: rgba(25, 19, 38, 0.1) 0px 2px 12px -8px,
      //   rgba(25, 19, 38, 0.05) 0px 1px 1px;
      // color: rgb(255, 255, 255);
      // overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      margin-top: 30px;
      p {
        letter-spacing: 1px;
        font-size: 13px;
        margin-top: 25px;
        color: $whiteColor;
        font-family: $baseFont;
        a {
          color: $white;
          text-decoration: underline;
        }
      }
      .btn {
        height: 46px;
      }
      .ref_login_btn {
        margin-top: 14px;
        padding: 0 57px;
        background: #111326;
        color: #e3d32d;
      }
    }
    .Unlockbox {
      margin-top: 40px;
      .UnlockboxTop {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        @media (max-width: 767px) {
          flex-direction: column;
        }
        .UnlockboxIn {
          width: 48% !important;
          @media (max-width: 767px) {
            width: 100% !important;
            margin-bottom: 20px;
          }
        }
      }
    }
    .UnlockboxIn {
      background-color: rgb(18, 24, 39);
      border-radius: 32px;
      box-shadow: rgba(25, 19, 38, 0.1) 0px 2px 12px -8px,
        rgba(25, 19, 38, 0.05) 0px 1px 1px;
      color: rgb(255, 255, 255);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      h3 {
        border-bottom: 1px solid #fff;
        padding: 24px;
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin: 0;
        display: block;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copy {
          color: #e3d32d !important;
          border: 2px solid #e3d32d !important;
          border-radius: 25px;
          padding: 6px 25px;
          width: 120px;
        }
      }
      p {
        padding: 24px;
        color: #e3d32d !important;
        font-weight: 600;
        width: 100%;
        color: rgb(255, 255, 255);
        box-sizing: border-box;
      }
      a {
        padding: 24px;
        color: #e3d32d !important;
        font-weight: 600;
        width: 100%;
        color: rgb(255, 255, 255);
        box-sizing: border-box;
        text-align: center;

        @media (max-width: 767px) {
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
