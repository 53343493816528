@import '../../assets/theme/_var.scss';

.card_style{
    border-radius: 5px;
    border:1px solid $secondaryColor;
    background: $baseColor;
    color: $whiteColor; 
    // padding:0 20px;
    &.mb-26{
    margin-bottom: 26px;
    }
    &.fullHeight{
        height: 100%;
    }
}
